import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImpApiServicesService {

  constructor(private http: HttpClient) { }

  get(api): Observable<any> {
    return this.http.get<any>(api);
  }

  post(api, data): Observable<any> {
    return this.http.post<any>(api, data);
  }

  uploadFile(api, id, file): Observable<any> {

    const myHeaders = new HttpHeaders();
    myHeaders.append('Content-Type', 'multipart/form-data');
    const formData = new FormData();
    formData.append('logo', file);

    return this.http.post<any>(api + id + '/upload-logo', formData, {
      headers: myHeaders,
    });
  }

  downloadFile(api): Observable<any> {
    const myHeaders = new HttpHeaders();
    myHeaders.append('Accept', 'application/pdf');
    return this.http.get(api, { headers: myHeaders, responseType: 'blob' });
  }

  put(api, data): Observable<any> {
    return this.http.put<any>(api, data);
  }

  delete(api) {
    return this.http.delete<any>(api);
  }

  reset_password(api, data, token) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });
    return this.http.post<any>(api, data, { headers: headers });

  }
}
