import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kpi-movement',
  templateUrl: './kpi-movement.component.html',
  styleUrls: ['./kpi-movement.component.scss']
})
export class KpiMovementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
