import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-document',
  templateUrl: './survey-document.component.html',
  styleUrls: ['./survey-document.component.scss']
})
export class SurveyDocumentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
