import { Component, DoCheck, Renderer2, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { ThemeService } from './services/theme.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  constructor(
    private permissionsService: NgxPermissionsService,
    private themeService: ThemeService,
    private renderer: Renderer2

  ) { }
  check(): void {
    if (location.pathname == '/login') {
      this.isLogin = false;
    }
  }



  user: any;
  isLogin = true;
  lang = localStorage.getItem('lang');
  public languagesList = [
    {
      code: 'ar',
      name: 'عربي',
      shorthand: 'AR',
      dir: 'rtl'
    },
    {
      code: 'en',
      name: 'English',
      shorthand: 'ENG',
      dir: 'ltr'
    },


  ];
  ngOnInit(): void {
    document.body.setAttribute('data-bs-theme', 'light');
    if (this.lang == null) {
      localStorage.setItem('lang', 'ar');
      let lang = this.languagesList.find(d => d.code == 'ar')
      document.body.style.direction = lang.dir
    } else {
      let check = localStorage.getItem('lang');
      let lang = this.languagesList.find(d => d.code == check)
      document.body.style.direction = lang.dir
    }

    this.check()
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      const permissions = this.user?.components?.flatMap(d => d.permissions.map(d => d.code));
      this.permissionsService.loadPermissions(permissions);
    }

    //

    this.checkTheme();


    // icon

    const linkElement = this.renderer.selectRootElement('#icon-tafweej', true);
    this.renderer.setAttribute(linkElement, 'href', environment.iconHeader);
  }

  checkTheme() {
    this.themeService.updateTheme();
  }

}
